import React, { useRef, useEffect, Fragment, useContext, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import appContext from '../Context/appContext';
import * as WEBSITES from '../constants/website';

import useSegment from '../hooks/segment';
import CartWidgetItem from './CartWidgetItem';
import StorageService from '../services/storageService';
import RecommendedProducts from './cart/RecommendedProducts';
import { useUpsellProducts } from '../hooks/cart';
import { isAddToCartRecommendationApplication, isRecommendedCartUpsellApplicable } from '../constants/feature';

import HappyHours from './HappyHours';
import { useDeliveryDetails } from '../hooks/app';
import notificationTypes from '../constants/modalNotificationConst';
import { useDispatch, useSelector } from 'react-redux';
import { popNotification } from '../redux/slices/modalSlice';
import { setAllCartProductsWidget, setCartWidget, setIsAddToCartLoading } from '../redux/slices/cartSlice';

const CartWidget = ({ goToCart, setClickUnmount, className }) => {
  // const {
  //   // state: {
  //   // cart: {
  //   //   cartData: { beforeTaxDiscountedAmount, calculatedAmount, cart_items: cartItems = [] } = {},
  //   //   cartWidgetProduct,
  //   //   productAddedToCart
  //   // },
  //   // user: { couponData },
  //   // },
  //   dispatch
  // } = useContext(appContext);

  const {
    cartData: { beforeTaxDiscountedAmount, calculatedAmount, cart_items: cartItems = [] } = {},
    cartWidgetProduct,
    productAddedToCart
  } = useSelector(state => state.cart) || {}
  const couponData = useSelector(state => state.user.couponData)

  const dispatchRedux = useDispatch()

  const notificationsOverlap = useSelector(state => state.modal.notificationsOverlap)

  const notifications = useSelector(state => state.modal.notifications)

  const zoneName = StorageService.getZoneLocalStorage();

  const {
    data: { happyHours }
  } = useDeliveryDetails();
  const { is_enable: is_happy_hours_enable } = happyHours || {};

  const { discount_code: discountCode } = couponData;

  const totalNotificationLength = notifications.length + notificationsOverlap.length;

  const { trackEvent } = useSegment();

  const {
    data: { upsellProducts }
  } = useUpsellProducts();

  const ref = useRef(null);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('hide-acsb-icon');
    return () => {
      body.classList.remove('hide-acsb-icon');
    };
  }, []);

  let productDetails = {};
  cartItems
    .filter(cartItem =>
      cartItem.quantity > 0 && cartItem.bundle_id
        ? cartItem.bundle_id == cartWidgetProduct
        : cartItem.product_id == cartWidgetProduct)
    .map(cartItem => (productDetails = cartItem));
  const prodObj = {
    event_category: 'Add to Cart Popup',
    coupon: discountCode || '',
    currency: 'usd',
    name: productDetails.product_name || productDetails.name || productDetails.bundle_name,
    original_price: productDetails.price_without_deal || productDetails.price,
    position: '1',
    product_id: productDetails.master_product_id,
    quantity: productDetails.quantity,
    sku: productDetails.bundle_id ? productDetails.bundle_id : productDetails.master_product_id,
    variant:
      productDetails.category_weight && productDetails.category_unit
        ? `${productDetails.category_weight} ${productDetails.category_unit}`
        : '',
    url: `${window.location.origin}/product/${productDetails.slug}`,
    zone_name: zoneName
  };

  useEffect(() => {
    if (isEmpty(productAddedToCart)) {
      // dispatch({ type: 'setIsAddToCartLoading', payload: true });
    }
    !isEmpty(productAddedToCart) &&
      trackEvent('Added to Cart Popup Viewed', { ...prodObj, recommendedProducts: productAddedToCart || [] });
  }, [cartWidgetProduct, productAddedToCart]);

  const goToCheckout = () => {
    setClickUnmount(true);
    const origin = window.location.origin;
    trackEvent('Checkout Clicked', { source: 'Added to cart widget', ...prodObj });
    goToCart();
    // dispatch({ type: 'setCartWidget', payload: false });
    dispatchRedux(setCartWidget(false))
  };

  const viewAllProductCart = () => {
    setClickUnmount(true);
    if (totalNotificationLength) {
      const { type } = notifications.length ? notifications[0] : notificationsOverlap[0];
      const { productDetails } = notificationTypes;
      if (type === productDetails) {
        dispatchRedux(popNotification())
      }
    }
    trackEvent('View Cart Clicked', prodObj, true);
    // dispatch({ type: 'setCartWidget', payload: false });
    dispatchRedux(setCartWidget(false))
    // dispatch({ type: 'setAllCartProductsWidget', payload: true });
    dispatchRedux(setAllCartProductsWidget(true))
  };

  const closeCartWidget = () => {
    setClickUnmount(true);
    const origin = window.location.origin;
    trackEvent('Added to Cart Closed', { type: 'Manual Close', ...prodObj }, true);
    // dispatch({ type: 'setCartWidget', payload: false });
    dispatchRedux(setCartWidget(false))
  };

  useEffect(() => {
    const origin = window.location.origin;
    // dispatch({ type: 'setIsAddToCartLoading', payload: true });
    dispatchRedux(setIsAddToCartLoading(true))
    trackEvent('Added to Cart Popup Viewed', prodObj);
    const listener = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        // dispatch({ type: 'setCartWidget', payload: false });
        dispatchRedux(setCartWidget(false))
      }
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, []);
  /*eslint-disable */
  return (
    <Fragment>
      {cartItems.length > 0 ? (
        <div
          className={`
            ${process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR ||
              process.env.NEXT_PUBLIC_APP === WEBSITES.GOVANA ||
              process.env.NEXT_PUBLIC_APP === WEBSITES.GETHERB ||
              process.env.NEXT_PUBLIC_DEFAULT_SITES
              ? 'cart-widget-parent container'
              : 'cart-widget-parent container-fluid cart-widget-parent-dtc'
            }
            ${className}
          `}
        >
          <div className="c-backdrop d-lg-none" />
          <div className="cart-box secondary-cart-widget-popup" ref={ref} id="cart-widget">
            <div className="c-header py-3 d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <i className="icon-tick-plus mr-2 c-icon text-primary added" />
                <span className="c-text align-self-center bold">Successfully added</span>
              </div>
              <button type="button" className="btn" onClick={closeCartWidget}>
                <i className="icon-close" />
              </button>
            </div>
            {is_happy_hours_enable ? <div className="hh-cartwidget"><HappyHours showText={false} /></div> : null}

            {/* {process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR ? <TeslaBanner /> : null} */}

            <div className="c-list pb-4 pt-3">
              <CartWidgetItem cartTotal={beforeTaxDiscountedAmount || calculatedAmount} />
              {isRecommendedCartUpsellApplicable ? <hr /> : upsellProducts?.length ? <hr /> : null}
              {cartItems.length ? <RecommendedProducts className="padding-15-side two-rem-mt cart-widget-reco-products" /> : null}
            </div >
            <div className="bottom-section padding-15-side has-box-shadow-top pt-3">
              {/* <div className="cart-total d-flex justify-content-between pt-3 bold " test-id="#cart_widget_total">
                <div className="total-text">
                  <p className="text-left mb-0 text-tax">Cart Net Subtotal</p>
                </div>
                <div className="price-container grassdoor-l-primary text-primary" >
                  {' '}
                  {beforeTaxDiscountedAmount
                    ? `$${parseFloat(beforeTaxDiscountedAmount).toFixed(2)}`
                    : calculatedAmount
                      ? `$${parseFloat(calculatedAmount).toFixed(2)}`
                      : '$0.00'}
                </div>
              </div>
              <div className="mb-2 text-tax">
                <small className="sm-text" test-id="#cart_widget_taxes_text">
                  Taxes and discounts calculated at checkout.
                </small>
              </div> */}
              <div className="text-center d-flex cart-widget-btn-container">

                <button
                  className="btn   view-cart-btn   p-2 justify-content-between mb-3"
                  type="button"
                  disabled={!cartItems.length}
                  onClick={viewAllProductCart}
                >
                  {`View Cart (${cartItems.length})`}
                </button>
                <button
                  className="btn btn-primary  checkout-btn   p-2 justify-content-between mb-3"
                  type="button"
                  disabled={!cartItems.length}
                  onClick={goToCheckout}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div >
        </div >
      ) : (
        <div></div>
      )}
    </Fragment >
  );
};

export default CartWidget;
