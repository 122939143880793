import React, { useContext } from 'react';
import Router from 'next/router';
import findIndex from 'lodash/findIndex';
import LoadImage from './LoadImage';
import Loader from './Loader';
import getTransformedImageURLs from '../helpers/getTransformedImageURLs';

import { useModifyCart } from '../hooks/cart';
import notificationTypes from '../constants/modalNotificationConst';

import appConfig from '../appConfig';
import appContext from '../Context/appContext';
import Price from './Price';
import BundlePrice from './BundlePrice';
import BuyXGetYOffersList from './BuyXGetYOffersList';
import * as WEBSITES from '../constants/website';
import { useGetTabFromProduct } from '../hooks/Shop';
import getProductType from '../helpers/getProductType';
import { ASAP, ASAP_AND_SCHEDULE } from '../constants/deliveryType';
import storageService from '../services/storageService';
import { useDeliveryDetails } from '../hooks/app';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification, popNotification, increaseURLstack, pushNotificationOverlap } from '../redux/slices/modalSlice';
import { setUrlBeforeProduct } from '../redux/slices/userSlice';
import { setAllCartProductsWidget, setCartWidget } from '../redux/slices/cartSlice';

const EmptyCart = '/static/images/emptycart.svg';
const isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';

const Item = ({ data: oldData, cartItems, couponDetails, cartTotal, hideProductWeight = false }) => {
  // const {
  //   // state: {
  //   //   cart: { updatingProductsList, cartData: { amount = 0 } = {} },
  //   //   user: { isLoggedIn }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const dispatchRedux = useDispatch()

  const { updatingProductsList, cartData: { amount = 0 } = {} } = useSelector(state => state.cart) || {}
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)

  const { updateCartItem, deleteCartItem } = useModifyCart();

  const {
    data: { asapEnabled, scheduleEnabled, isWalletCreditWithPurchaseApplicable }
  } = useDeliveryDetails();

  const data = { ...oldData };
  const productDeliveryType = getProductType(data);
  const updatingQuantity = updatingProductsList.includes(data.product_details_id);
  const tab = useGetTabFromProduct(data);

  function handleBulkProductPrice(product) {
    const cartItem = product;
    if (!isLoggedIn && cartItem.bulk_prices) {
      const index = findIndex(cartItem.bulk_prices, { quantity: cartItem.quantity });
      if (index === -1) {
        for (let i = cartItem.quantity; i > 0; i -= 1) {
          const location = findIndex(cartItem.bulk_prices, { quantity: i });
          if (location !== -1) {
            cartItem.price = cartItem.bulk_prices[location].price;
            break;
          }
        }
      } else {
        cartItem.price = cartItem.bulk_prices[index].price;
      }
    }
    return cartItem;
  }

  function handleQuantityChange(cartItem, quantity) {
    switch (quantity) {
      case 0: {
        let walletWithPurchaseProps = null;

        if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
          walletWithPurchaseProps = {
            currentProductDetails: cartItem || {},
            cartSubtotal: amount,
            dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
            requestedProductQuantity: 0,
            onRemove: () =>
              deleteCartItem({
                product: cartItem,
                immediate: true,
                type: 'cartWidget'
              })
          };
        }

        deleteCartItem({ product: cartItem, immediate: true, type: 'cartWidget', walletWithPurchaseProps });
        break;
      }
      default:
        {
          const product = handleBulkProductPrice({ ...cartItem, quantity });

          let walletWithPurchaseProps = null;

          if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
            walletWithPurchaseProps = {
              currentProductDetails: cartItem || {},
              cartSubtotal: amount,
              dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
              requestedProductQuantity: product.quantity,
              onRemove: () =>
                updateCartItem({
                  product,
                  scheduleType: 0,
                  force: true,
                  type: 'cartWidget'
                })
            };
          }

          updateCartItem({ product, scheduleType: 0, force: true, type: 'cartWidget', walletWithPurchaseProps });
        }
        break;
    }
  }

  function handleDelete() {
    handleQuantityChange(data, 0);
  }

  function handleIncreaseQuantity() {
    handleQuantityChange(data, Number(data.quantity) + 1);
  }

  function handleDecreaseQuantity() {
    handleQuantityChange(data, Number(data.quantity) - 1);
  }

  function showProductPage() {
    if (
      [
        WEBSITES.KOAN,
        WEBSITES.DRINKCANN,
        WEBSITES.WUNDER,
        WEBSITES.CANNADIPSTHC,
        WEBSITES.BEED,
        WEBSITES.POTLI,
        WEBSITES.ALTLIFE,
        WEBSITES.HELLOAGAIN,
        WEBSITES.VETCBD,
        // WEBSITES.HUMBOLDTFAMILYFARMS,
        WEBSITES.MAISONBLOOM,
        WEBSITES.BOASTCANNABIS,
        WEBSITES.RIGHTRATIO,
        WEBSITES.GETOOKA
      ].includes(process.env.NEXT_PUBLIC_APP)
    ) {
      if (data.bundle_id) {
        Router.push('/bundle/[slug]', `/bundle/${data.slug}?tab=${tab}`);
      } else {
        Router.push('/product/[slug]', `/product/${data.slug}?tab=${tab}`);
      }
    } else {
      // dispatch({ type: 'setCartWidget', payload: false });
      dispatchRedux(setCartWidget(false))
      let url = '';
      // dispatch({
      //   type: 'setUrlBeforeProduct',
      //   payload: { urlBeforeProduct: `${window.location.pathname + window.location.search}` }
      // });
      dispatchRedux(setUrlBeforeProduct({ urlBeforeProduct: `${window.location.pathname + window.location.search}` }))
      let isBundle = true;
      if (data.bundle_id) {
        url = `/bundle/${data.slug}?tab=${tab}`;
      } else {
        isBundle = false;
        url = `/product/${data.slug}?tab=${tab}`;
      }
      dispatchRedux(popNotification())

      dispatchRedux(pushNotification({ type: notificationTypes.productDetails, data: { slug: data.slug, tab, page: '', isBundle } }))
      dispatchRedux(increaseURLstack())
      window.history.replaceState({ ...window.history.state, as: url, url }, '', url);
    }
  }

  function renderSaleInfo(isSomeDealAvailable, discountCode) {
    if (!!isSomeDealAvailable && discountCode) {
      return (
        <small className="d-block  ">{data.message ? data.message : 'Coupon code discount not applied to sale item.'}</small>
      );
    }
    if (data.message) {
      return <small className="d-block">{data.message}</small>;
    }
    return null;
  }

  const applyDiscount = price => {
    const { discount = 0, slash_price: slashPrice } = couponDetails;
    const { asap, schedule, menu } = data;
    if (slashPrice) {
      switch (Number(couponDetails.eligible_delivery_type)) {
        case 1:
          if (asap && menu != 2) {
            let discountedAmount = price * ((100 - discount) / 100);
            if (Math.round(discountedAmount) !== discountedAmount) {
              discountedAmount = discountedAmount.toFixed(2);
            }
            return discountedAmount;
          }
          return price;
        case 2:
          if (schedule && menu != 1) {
            let discountedAmount = price * ((100 - discount) / 100);
            if (Math.round(discountedAmount) !== discountedAmount) {
              discountedAmount = discountedAmount.toFixed(2);
            }
            return discountedAmount;
          }
          return price;
        default:
          let discountedAmount = price * ((100 - discount) / 100);
          if (Math.round(discountedAmount) !== discountedAmount) {
            discountedAmount = discountedAmount.toFixed(2);
          }
          return discountedAmount;
      }
    } else {
      return price;
    }
  };

  const weight =
    (data.category_unit || data.product_unit) === '1/8 oz' || (data.category_unit || data.product_unit) === '1/2 oz'
      ? `${data.category_unit || data.product_unit || ''}`
      : `${data.category_weight || data.product_weight || ''}${data.category_unit || data.product_unit || ''}`;

  const productImage = getTransformedImageURLs(data.image || data.product_image).srcSmall;
  const isSomeDealAvailable = data.is_pre_deal_available == 1 || data.is_deal_available;
  const bulkBasePrice = data && data.bulk_base_price ? data.bulk_base_price : false;
  let orignalPrice = data.price_without_deal ? data.price_without_deal : bulkBasePrice;
  const { discount_code: discountCode, type } = couponDetails;
  const maxQuantity = data.product_max_quantity;

  // case for category volume pricing
  if (data.offer_category_id && data.category_volume_discounts && !data.is_pre_deal_available && !data.is_deal_available) {
    let quantity = 0;
    cartItems.forEach(cartItem => {
      if (
        cartItem.offer_category_id == data.offer_category_id &&
        !cartItem.is_deal_available &&
        !cartItem.is_pre_deal_available
      ) {
        quantity += cartItem.quantity;
      }
    });
    const discount = (() => {
      const discounts = [...data.category_volume_discounts].sort((a, b) => b.units - a.units);
      let finalDiscount = 0;
      discounts.some(discountIternal => {
        if (quantity >= discountIternal.units) {
          finalDiscount = discountIternal.discount;
          return true;
        }
        return false;
      });
      return finalDiscount;
    })();
    const discountedPrice = (applyDiscount(data.price, couponDetails) * (100 - discount)) / 100;
    orignalPrice = data.price;
    data.price = discountedPrice;
  }

  const getProductTypeLabel = () => {
    return productDeliveryType === ASAP_AND_SCHEDULE ? null : productDeliveryType === ASAP ? 'ASAP' : 'SCHEDULED';
  };

  return (
    <div className="item pb-0">
      <div className="d-flex  relative align-items-center">
        <div className=" mt-1 c-img-container align-self-center">
          <LoadImage
            src={productImage}
            alt={
              data.image_alt_tag && data.image_alt_tag ? data.image_alt_tag : data.name || data.product_name || data.bundle_name
            }
            height={60}
            width={60}
          />
        </div>

        <div className="col p-0 pr-2">
          <div className="product-name" role="presentation" onClick={showProductPage}>
            {data.name || data.product_name || data.bundle_name}
          </div>

          {data?.brand_names?.length ? <div className=" product-brand">{data.brand_names[0]}</div> : null}

          <div className="d-flex mt-1 align-items-center bottom-sec mb-1">
            <div className="price-container">
              {data.bundle_id ? (
                <BundlePrice
                  className="price text-primary"
                  currency={data.category_currency}
                  orignalPrice={data.original_price}
                  price={data.price}
                />
              ) : (
                <Price
                  productDetails={data}
                  orignalPrice={orignalPrice}
                  priceClassName="text-primary"
                  categorySalePriceId={data.category_sale_price_id}
                />
              )}
            </div>

            {data.category_weight && data.category_unit && !hideProductWeight ? (
              <span className="cart-weight">
                <span className="cart-details-line mx-2">|</span>
                {`${weight}`}
              </span>
            ) : null}

            {data?.concentrated_weight || data?.non_concentrated_weight ? (
              <span className="cart-weight">
                <span className="cart-details-line mx-2">|</span>
                <i className={`fs-16 text-primary mr-1 ${data?.concentrated_weight ? 'icon-conc' : 'icon-non-conc'}`} />
              </span>
            ) : null}
          </div>

          {asapEnabled && scheduleEnabled ? (
            <small className={`mt-2 ${productDeliveryType === ASAP_AND_SCHEDULE ? ' ' : 'product-label'}`}>
              {getProductTypeLabel()}
            </small>
          ) : null}

          {/* {popularProductIds &&
            popularProductIds.length && showRecommendedProducts ?
            popularProductIds.map(items => items == data.master_product_id && <div className="best-seller">Popular</div>) : null} */}
        </div>

        <div className="count-holder text-right align-self-start mt-2">
          <>
            {data.quantity == 1 ? (
              <button
                disabled={updatingQuantity || (isLoggedIn && !data.cart_id)}
                type="button"
                className="btn update-count-btn align-middle delBtn"
                onClick={handleDelete}
              >
                <span className="icon-trash-alt2 delete-btn" />
              </button>
            ) : (
              <button
                disabled={updatingQuantity || (isLoggedIn && !data.cart_id)}
                type="button"
                className="btn update-count-btn align-middle"
                onClick={handleDecreaseQuantity}
              >
                <span>
                  <i className="icon-minus count-btn bold" />
                </span>
              </button>
            )}

            <span className="count d-inline-block bold align-middle">{data.quantity}</span>

            <button
              disabled={
                data.quantity >= maxQuantity || data.is_pre_deal_available || updatingQuantity || (isLoggedIn && !data.cart_id)
              }
              type="button"
              className="btn update-count-btn align-middle "
              onClick={handleIncreaseQuantity}
            >
              <span>
                <i className="icon-plus count-btn bold" />
              </span>
            </button>
          </>
        </div>
      </div>

      {data?.offer_product?.length ? <BuyXGetYOffersList data={data} /> : null}

      {/* <hr className="" />
      <div className="cart-total d-flex justify-content-between pt-3 bold ">
        <div className="total-text">
          <p className="text-left mb-0">Cart Net Subtotal</p>
        </div>
        <div className="price-container grassdoor-l-primary">
          {' '}
          {data.category_currency}
          {Number.parseFloat(cartTotal).toFixed(2)}
        </div>
      </div> */}
      {/* <div className="cart-total mt-2 px-2">
        <div className="total-text">
          <p className="text-left mb-0">Your Cart Total</p>
        </div>
        <div className="price-container grassdoor-l-primary">
          {data.category_currency}
          {cartTotal}
        </div>
      </div> */}

      {/* {type == 2 ? renderSaleInfo(isSomeDealAvailable, discountCode) : null} */}

      {data.bundle_id && discountCode && <small className="d-block  ">Coupon code discount not applied to product bundle.</small>}
    </div>
  );
};

const CartWidgetItems = ({ cartTotal }) => {
  // const {
  //   // state: {
  //   //   user: { couponData },
  //   //   cart: { cartWidgetProduct, cartData: { cart_items: cartItems = [] } = {}, cartData }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const couponData = useSelector(state => state.user.couponData)
  const { cartWidgetProduct, cartData: { cart_items: cartItems = [] } = {}, cartData } = useSelector(state => state.cart) || {}
  const dispatchRedux = useDispatch()
  const hideCartWidget = () => {
    // dispatch({ type: 'setAllCartProductsWidget', payload: false });
    dispatchRedux(setAllCartProductsWidget(false))
  };

  if (!cartData) {
    return <Loader />;
  }
  if (!cartItems.length) {
    return (
      <>
        <div className="p-3 text-center">
          <h3 className="cat-menu-title">Your bag is empty</h3>
          <p className="md-grey-color">Please add items to your shopping bag. Enjoy shopping with us.</p>
        </div>

        <div className="bottom-section padding-15-side has-box-shadow-top one-rem-mt pt-3 text-center">
          <button
            type="button"
            className="btn btn-primary  checkout-btn p-2 justify-content-between mb-lg-3"
            onClick={hideCartWidget}
          >
            Keep shopping!
          </button>
        </div>
      </>
    );
  }
  return (
    <>
      {cartItems
        .filter(cartItem =>
          cartItem.quantity > 0 && cartItem.bundle_id
            ? cartItem.bundle_id == cartWidgetProduct
            : cartItem.product_id == cartWidgetProduct
        )
        .map(cartItem => (
          <Item
            data={cartItem}
            couponDetails={couponData}
            loader={cartItem.loader}
            hideProductWeight={appConfig.hideProductWeight}
            cartItems={cartItems}
            cartTotal={cartTotal}
          />
        ))}
    </>
  );
};
export { CartWidgetItems as default, Item };
